import { dict } from '@/utils/dict';
import { useToast } from 'vue-toast-notification/src';
import useUser from '@/hooks/useUser';
import { router } from '@inertiajs/vue3';
import { useIsPublicPage } from '@/hooks/useIsPublicPage';
import * as hijri from 'hijri-js';
import dayjs from 'dayjs';

export const toast = (message, type = 'success', timer = 5000) => {
  useToast().open({ message: message, type: type, duration: timer, position: 'top-left' });
};

export const isSlug = (word) => /[a-z]|-/.test(word);

export const route = window.route;

export const debounce = (fn, delay) => {
  let timeout;

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => fn(...args), delay);
  };
};

export function throttle(func, wait = 100) {
  let timer = null;
  return function (...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

export const trans = (word, defaultWord = null) => {
  return dict[word] ?? defaultWord ?? word;
};

export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const can = (permission) => {
  return true;

  const user = useUser();

  if (!user) return false;

  return user.is_owner || user.permissions.some((p) => p.name === permission);
};

export const toArabicDigits = (string) =>
  string.replace(/\d/g, (w) => ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'][+w]);

const h = hijri.initialize();

export const formatHijriDate = (date) => h.toHijri(dayjs(date).format('DD/MM/YYYY'), '/').plain;

export const navigateToNode = (nodeId) => {
  const isPublicPage = useIsPublicPage();

  router.visit(isPublicPage ? `/${nodeId}` : route('branches.show', nodeId), {
    only: ['currentNode'],
  });
};

export const formatLocation = ({ country, city, district }) => {
  let location = '';

  if (country) {
    location += country.name;
  }

  if (city) {
    location += ` - ${city.name}`;
  }

  if (district) {
    location += ` - ${district.name}`;
  }

  return location;
};

interface Node {
  id: number;
  parent_id: number | null;
  children?: Node[];

  [key: string]: any;
}

export function arrayToTree(items: Node[]): Node {
  const itemMap = new Map<number, Node>();
  const roots: Node[] = [];

  // First pass: create nodes map
  for (const item of items) {
    // Create a new object with children array
    itemMap.set(item.id, { ...item, children: [] });
  }

  // Second pass: establish relationships
  for (const item of itemMap.values()) {
    if (item.parent_id === null) {
      roots.push(item);
    } else {
      const parent = itemMap.get(item.parent_id);
      if (parent) {
        parent.children?.push(item);
      }
    }
  }

  return roots[0];
}

export function flattenTree(node, parentNode, result) {
  const fullName = truncate(node.full_name, 200);

  result.data.push({
    name: fullName,
    label: node.name,
    count: node.descendants_count,
    depth: node.depth,
  });

  if (parentNode) {
    result.links.push({
      source: truncate(parentNode.full_name, 200),
      target: fullName,
      value: node.descendants_count,
      depth: node.depth,
    });
  }

  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      flattenTree(child, node, result);
    }
  }
}

export function findNodeWithMultipleChildren(node) {
  if (node && node.children && node.children.length > 1) {
    return node;
  }

  if (node && node.children && node.children.length === 1) {
    return findNodeWithMultipleChildren(node.children[0]);
  }

  return null;
}

export const truncate = (string, length = 50) =>
  string?.length > length ? string.substring(0, length) + '...' : string;

export function mapTree(array, transformFn) {
  return array.map((node) => {
    const newNode = transformFn(node);
    if (node.children && node.children.length > 0) {
      newNode.children = mapTree(node.children, transformFn);
    }
    return newNode;
  });
}

export function findTree(root, predicate) {
  if (predicate(root)) {
    return root;
  }

  if (root.children && root.children.length > 0) {
    for (const child of root.children) {
      const found = findTree(child, predicate);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

export const ordinalsAr = (num: number, isFeminine: boolean) => {
  let feminineSuffix = 'ة';

  let specialNums = {
    1: {
      masculine: 'الأول',
      feminine: 'الأولى',
    },
    10: {
      masculine: 'العاشر',
      feminine: 'العاشرة',
    },
  };

  let lastDigits = {
    10: 'عشر',
    20: 'العشرون',
    30: 'الثلاثون',
    40: 'الأربعون',
    50: 'الخمسون',
    60: 'الستون',
    70: 'السبعون',
    80: 'الثمانون',
    90: 'التسعون',
    // you have to add the rest here
  };

  let firstDigits = {
    1: 'الحادي',
    2: 'الثاني',
    3: 'الثالث',
    4: 'الرابع',
    5: 'الخامس',
    6: 'السادس',
    7: 'السابع',
    8: 'الثامن',
    9: 'التاسع',
  };

  if (specialNums[num])
    return isFeminine ? specialNums[num]['feminine'] : specialNums[num]['masculine'];

  if (firstDigits[num]) return isFeminine ? firstDigits[num] + feminineSuffix : firstDigits[num];

  if (lastDigits[num]) return lastDigits[num];

  let firstDigit = num % 10;
  let lastDigit = num - firstDigit;

  if (isFeminine) {
    if (lastDigit < 20)
      return (
        firstDigits[firstDigit] + feminineSuffix + ' ' + lastDigits[lastDigit] + feminineSuffix
      );
    else return firstDigits[firstDigit] + feminineSuffix + ' و' + lastDigits[lastDigit];
  } else {
    if (lastDigit < 20) return firstDigits[firstDigit] + ' ' + lastDigits[lastDigit];
  }

  return firstDigits[firstDigit] + ' و' + lastDigits[lastDigit];
};

export const toEnglishDigits = (str: string) => {
  let e = '۰'.charCodeAt(0);
  str = str.replace(/[۰-۹]/g, (t) => t.charCodeAt(0) - e);

  e = '٠'.charCodeAt(0);
  str = str.replace(/[٠-٩]/g, (t) => t.charCodeAt(0) - e);

  return str.trim();
};

export const formatFirstThreeNames = (node) => {
  return (
    node.full_name
      ?.split(/ (بن|بنت) /)
      .slice(0, 5)
      .join(' ') ?? node.name
  );
};
